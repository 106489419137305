import React, { useState } from 'react';
import { Link } from 'gatsby';
import LockSubIcon from '../../assets/icons/sub-lock.svg';
import UnlockSubIcon from '../../assets/icons/open-sub-lock.svg';
import VideoThumbDefault from '../../assets/images/video-thumb-default.jpg';
import ReactPlayer from 'react-player';
import { FaPlay } from 'react-icons/fa6';
import { useIsMainSubscribed } from '@src/hooks/user';

const isVideoFile = (url) => {
  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov'];
  return videoExtensions.some((ext) => url.toLowerCase().endsWith(ext));
};

const PlayIcon = () => {
  return (
    <div className="play-icon">
      <FaPlay size={42} style={{ color: `hsla(var(--mmc-blue))` }} />
    </div>
  );
};

const MediaContent = ({ url, videoThumbnail, isLastPlayed, onPlay }) => {
  if (!url) return null;

  return isVideoFile(url) ? (
    <ReactPlayer
      url={url}
      width="100%"
      height="100%"
      controls={false}
      playIcon={<PlayIcon />}
      playing={true}
      loop
      muted={!isLastPlayed}
      onPlay={onPlay}
      light={
        <div className="poster">
          <img src={videoThumbnail || VideoThumbDefault} alt="" className="poster-img" />
        </div>
      }
      style={{
        position: 'relative',
        objectFit: 'cover',
        borderRadius: '6px',
        minHeight: '200px',
        maxHeight: '200px',
      }}
    />
  ) : (
    <img src={url} alt="" />
  );
};

export default function InteractiveLearning({ block }) {
  const { heading, exercises } = block;
  const [lastPlayedVideo, setLastPlayedVideo] = useState(null);

  const isSubscribed = useIsMainSubscribed();

  const handleVideoPlay = (slug) => {
    setLastPlayedVideo(slug);
  };

  const goToInteractiveLearningPage = (slug, subscriptionOnly = false) => {
    if (!subscriptionOnly) return `/interactive-learning${slug}`;
    return isSubscribed ? `/interactive-learning${slug}` : '/subscriptions';
  };

  return (
    <div className="interactive-learning">
      <div className="main-heading">
        {heading && <h3>{heading}</h3>}
        <div className="main-heading-icon">
          <img alt="Subscription Icon" src={LockSubIcon} />
          <span>Requires Subscription</span>
        </div>
      </div>

      <div className="brainteasers-container">
        {exercises?.map(
          ({ title, slug, thumbnail, subscriptionOnly, videoThumbnail, buttonText }) => {
            return (
              <div
                to={goToInteractiveLearningPage(slug, subscriptionOnly)}
                key={slug}
                className="brainteaser-grid-item"
                {...(!isSubscribed && { 'data-subscription-only': subscriptionOnly })}
              >
                <div className="image-container" style={{ position: 'relative' }}>
                  {thumbnail && (
                    <MediaContent
                      url={thumbnail.url}
                      videoThumbnail={videoThumbnail?.url}
                      isLastPlayed={lastPlayedVideo === slug}
                      onPlay={() => handleVideoPlay(slug)}
                    />
                  )}
                </div>
                <div className="title-container">
                  <div className="title-wrapper">
                    {subscriptionOnly && (
                      <img
                        alt="Subscription Icon"
                        src={isSubscribed ? UnlockSubIcon : LockSubIcon}
                      />
                    )}
                    <p>
                      <Link to={goToInteractiveLearningPage(slug, subscriptionOnly)}>{title}</Link>
                    </p>
                  </div>
                  <div>
                    <Link
                      className="button"
                      to={goToInteractiveLearningPage(slug, subscriptionOnly)}
                    >
                      {buttonText || 'Play Game'}
                    </Link>
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
      <div className="grid"></div>
    </div>
  );
}
