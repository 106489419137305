import React from 'react';
import { AboutSection } from '@src/sections';
import TinyMCEViewer from '@src/components/Common/TinyMCEViewer/TinyMCEViewer';

export default function SectionBlock({ block }) {
  const { name, title, description2, callToAction } = block;

  if (name === 'about-author') {
    const data = { ...block, callToAction: callToAction };
    return <AboutSection data={data} key={name} />;
  }

  return (
    <>
      <h3 id={name}>{title}</h3>
      <TinyMCEViewer content={description2} />
    </>
  );
}
