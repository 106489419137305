import React from 'react';
import InteractiveLearning from './InteractiveLearning';
import SectionBlock from './SectionBlock';
const BlockFactory = ({ blocks }) => {
  //   console.log(blocks);

  if (!blocks) return null;

  return blocks.map((block) => {
    switch (block.__typename) {
      case 'DatoCmsSectionBlock':
        return <SectionBlock key={block.id} block={block} />;
      case 'DatoCmsInteractiveLearning':
        return <InteractiveLearning key={block.id} block={block} />;
      default:
        console.log(`Couldn't find a block for ${block.__typename}`);
        return null;
    }
  });
};

export default BlockFactory;
